import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Popup from '../components/Popup';
import '../styles/policy.scss';

const ContactUs = () => {
  const [modalVisible, setModalVisible] = useState('');

  const togglePopup = useCallback(
    (value) => setModalVisible((state) => (state === value ? '' : value)),
    [],
  );
  const closePopup = useCallback(() => setModalVisible(0), []);

  useEffect(() => {
    modalVisible
      ? document.body.classList.add('overflow')
      : document.body.classList.remove('overflow');
  }, [modalVisible]);

  return (
    <Layout togglePopup={togglePopup}>
      <SEO title="Contact Us" />
      <Popup modalVisible={modalVisible === 'spaces'} closePopup={closePopup}>
        <div className="space-video">
          <video
            autoPlay
            loop
            muted
            playsInline
            src="/video/spaces.mp4"
            style={{ width: '500px' }}></video>
        </div>
        <h4>Spaces by Brutask</h4>
        <p>
          Spaces by Brutask is created to replicate your virtual office.
          Navigate across floors, join a room &amp; discuss with your team via
          audio &amp; video. You can also share your screen with all othe
          participants inside the room. What's better - you can share the room
          link with external members outside your team, and they can join
          without any login!
        </p>
        <a className="button" href="http://app.brutask.com/">
          Get started
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
            <path
              d="M6.60019 0.199951L5.4722 1.32795L9.93619 5.79995H0.200195V7.39995H9.93619L5.4722 11.872L6.60019 13L13.0002 6.59995L6.60019 0.199951Z"
              fill="white"
            />
          </svg>
        </a>
        <a
          className="button button-unstyled"
          href="https://www.loom.com/share/c0ad1d9ddb6f48fe9e1040c7f6550ca3"
          target="_blank"
          rel="noopener noreferrer">
          Watch Demo
        </a>
      </Popup>
      <Popup
        modalVisible={modalVisible === 'pricing'}
        closePopup={closePopup}
        className="popup-center">
        <h3>
          Pay as you <span className="yellow">grow</span>
        </h3>
        <p>
          You will be charges <span className="semi-bold">$15/month</span> for
          the plan that will include: 10 members, 3 floors and unlimited guest
          access. <span className="semi-bold">$2</span> would be charged for
          every additional member
        </p>
        <a className="button" href="http://app.brutask.com/">
          Get started
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
            <path
              d="M6.60019 0.199951L5.4722 1.32795L9.93619 5.79995H0.200195V7.39995H9.93619L5.4722 11.872L6.60019 13L13.0002 6.59995L6.60019 0.199951Z"
              fill="white"
            />
          </svg>
        </a>
      </Popup>
      <section className="policy cancellation-policy">
        <div className="container">
          <div className="inner-common-hero">
            <h1 className="privacy-head">Contact Us</h1>
          </div>
        </div>
        <section>
          <div className="container">
            <div className="privacy-content">
              <p>
                In case of any queries, issues or concerns, please reach out to
                us at{' '}
                <a
                  href="mailto:support@brutask.com"
                  className="green semi-bold">
                  support@brutask.com
                </a>
                . We shall reply to your request at the earliest possible.
              </p>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default ContactUs;
