import React from 'react';
import './popup.scss';

function Popup({ modalVisible, closePopup = '', children, className = '' }) {
  return (
    <div className={`popup-main ${modalVisible ? 'open' : ''} ${className}`}>
      <div className="popup-block">
        {closePopup ? (
          <button className="close-popup" onClick={closePopup}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.99998 7.11489L15.6 0.514893L17.4853 2.40023L10.8853 9.00023L17.4853 15.6002L15.6 17.4856L8.99998 10.8856L2.39998 17.4856L0.514648 15.6002L7.11465 9.00023L0.514648 2.40023L2.39998 0.514893L8.99998 7.11489Z"
                fill="#091610"
              ></path>
            </svg>
          </button>
        ) : (
          ''
        )}
        <div className="popup-content relative">
          <div className="decoration hidden-mobile">
            <img src="/img/zigzag-blue.svg" alt="decoration-1" />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Popup;
